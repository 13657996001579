import React from "react";
import { Route } from "react-router-dom";
import NotFoundPage from "../features/components/not_found";

const PrivateRoute = ({ component: Component, allowedRoles, userRole, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            // console.log("User Role:", userRole);
            // console.log("Allowed Roles:", allowedRoles);
            // console.log("Access Granted:", allowedRoles.includes(userRole));

            return allowedRoles.includes(userRole) ? (
                <Component {...props} />
            ) : (
                <NotFoundPage />

            );
        }}
    />
);

export default PrivateRoute;


