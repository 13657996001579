import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import appUrl from "../../../../constants/appUrl";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiClient from "../../../../services/axios_api";

const AddEditOrganization = ({
  onHide,
  editable,
  setRes,
  setDialog,
  rowData,
}) => {
  const [loading, setLoading] = useState(false);
  const [imageBase64, setImageBase64] = useState("");
  const [fileName, setFileName] = useState("");

  const handleImageUpload = (event) => {
    const file = event.files[0];
    setFileName(file.name);
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result.split(",")[1];
      setImageBase64(base64Image);
      formik.setFieldValue("logoImageBase64", base64Image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Required")
      .matches(
        /^[A-Za-z\s]+$/,
        "Name must contain only alphabetic characters and spaces"
      )
      .max(30, "Name must be at most 30 characters long"),
    // address: Yup.string()
    //   .required("Address is required")
    //   .min(5, "Address must be at least 5 characters")
    //   .max(30, "Address must be at most 99 characters"),
    // email: Yup.string()
    //   .email("Invalid email format")
    //   .required("Email is required"),
    phoneNumber:  Yup.string()
          .matches(
            /^(03\d{9}|051\d{7})$/,
            "ORG Number must start with 03 and be 11 digits long, or start with 051 and be 10 digits long"
          )
          .notRequired(),
    logoImageBase64: Yup.string().required("Logo image is required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      address: "",
      email: "",
      phoneNumber: "",
      logoImageBase64: "",
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        if (data.logoImageBase64 && data.logoImageBase64.startsWith("http")) {
          // console.log(
          //   "Removing URL from logoImageBase64:",
          //   data.logoImageBase64
          // );
          delete data.logoImageBase64;
        }

        const payload = {
          name: data.name,
          logoImageBase64: imageBase64,
          address: data.address,
          email: data.email,
          phoneNumber: data.phoneNumber,
          ...(editable && { id: rowData.id }),
        };

        const response = editable
          ? await apiClient.put("/Organizations/update", payload)
          : await apiClient.post("/Organizations/add", payload);

        setRes(response.data);

        toast.success(
          editable
            ? "Organization Updated Successfully"
            : "Organization Added Successfully"
        );
        setDialog(false);
      } catch (error) {
        const errorMessage =
          error.response?.data?.responseDescription ||
          "Operation failed, please try again";

        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (editable) {
      formik.setFieldValue("name", rowData?.name);
      formik.setFieldValue("address", rowData?.address);
      formik.setFieldValue("email", rowData?.email);
      formik.setFieldValue("phoneNumber", rowData?.phoneNumber);
      // formik.setFieldValue(
      //   "phoneNumber",
      //   rowData?.phoneNumber?.replace(/^\+92/, "")
      // );
      formik.setFieldValue(
        "logoImageBase64",
        rowData?.logoUrl ? `${appUrl.baseUrl}${rowData.logoUrl}` : ""
      );
    }
  }, [editable, rowData]);

  const handleImageError = (e) => {
    e.target.onerror = null; 
    e.target.src = "https://placehold.co/500x500?text=No+Image";
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-6">
            <label htmlFor="name" style={{ fontWeight: "bold" }}>
              Name <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              placeholder="Enter name"
              keyfilter={/^[a-zA-Z\s]*$/}
              maxLength={30}
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="address" style={{ fontWeight: "bold" }}>
              Address
               {/* <span className="Staric-Custom"> *</span> */}
            </label>
            <InputText
              placeholder="Enter address"
              maxLength={99}
              id="address"
              name="address"
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
            {/* {formik.touched.address && formik.errors.address ? (
              <div className="error">{formik.errors.address}</div>
            ) : null} */}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="email" style={{ fontWeight: "bold" }}>
              Email 
              {/* <span className="Staric-Custom"> *</span> */}
            </label>
            <InputText
              placeholder="Enter email"
              id="email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {/* {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null} */}
          </div>

          <div className="field  md:col-6">
            <label htmlFor="phoneNumber" style={{ fontWeight: "bold" }}>
              ORG Number
               {/* <span className="Staric-Custom"> *</span> */}
            </label>
            <InputText
              maxLength={11}
              id="phoneNumber"
              name="phoneNumber"
              value={formik.values.phoneNumber}
              placeholder="Enter ORG Number"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                formik.setFieldValue("phoneNumber", sanitizedValue);
              }}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="error">{formik.errors.phoneNumber}</div>
            ) : null}
          </div>

          <div className="field md:col-6">
            <label htmlFor="logoImageBase64" style={{ fontWeight: "bold" }}>
              Upload Logo <span className="Staric-Custom"> *</span>
            </label>
            <FileUpload
              mode="basic"
              name="logoImageBase64"
              accept="image/*"
              maxFileSize={1000000}
              onSelect={handleImageUpload}
              auto
            />
            {fileName && (
              <p
                style={{
                  marginBottom: "-8px",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              >
                Selected file:
              </p>
            )}

            {formik.values.logoImageBase64 && (
              <div style={{ marginTop: "10px" }}>
                <img
                  src={
                    formik.values.logoImageBase64.startsWith("http")
                      ? formik.values.logoImageBase64
                      : `data:image/jpeg;base64,${formik.values.logoImageBase64}`
                  }
                  alt=""
                  onError={handleImageError}
                  style={{
                    width: "100%",
                    height: "100px",
                    objectFit: "contain",
                    border: "1px solid rgba(206, 212, 218, 0.82)",
                    borderRadius: "12px",
                  }}
                />
              </div>
            )}

            {formik.touched.logoImageBase64 &&
              formik.errors.logoImageBase64 && (
                <div className="error">{formik.errors.logoImageBase64}</div>
              )}
          </div>

          <div className="buttons-container">
            <div className="button-group">
              <Button
                className="custom-white w100"
                label="Cancel"
                type="button"
                onClick={onHide}
              />
            </div>

            <div className="button-group">
              <Button
                loading={loading}
                className="custom-btn"
                label={editable ? "Update" : "Add"}
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditOrganization;
