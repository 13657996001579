import React, { useState, useRef, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddEditGateKeeper from "../components/add_edit_gatekeeper";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Loader from "../../../components/loader";
import {
  formatDate,
  renderTextWithTooltip,
  formatTo12Hour,
  emailsRenderTextWithTooltip,
} from "../../../components/helper";
import { Tag } from "primereact/tag";
import editIcon from "../../../../../assets/icons/bx_edit.png";
import * as XLSX from "xlsx";
import apiClient from "../../../../services/axios_api";

const GateKeeper = () => {
  const [rowselect, setRowselect] = useState(null);
  const [dialog, setDialog] = useState();
  const [gateKeeperList, setGateKeeperList] = useState();
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const dt = useRef(null);
  const [expandedRows, setExpandedRows] = useState(null);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

 const GetGatesKeeper = async () => {
  try {
    setLoading(true);
    const response = await apiClient.get("/TellerUser/all");
    setGateKeeperList(response.data?.data);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

  const onHide = () => {
    setDialog(false);
  };

  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setDialog(true);
  };

  const updateStatus = async (id, isActive) => {
    setLoading(true);
  
    try {
      const payload = {
        id,
        isActive,
      };
      const response = await apiClient.put("/TellerUser/update/status", payload);
      if (response && response.data) {
        setRes(response.data);
        toast.success(`Gate Keeper is now ${isActive ? "Active" : "In-active"}`);
      }
    } catch (error) {
      toast.error(`Failed to update status.`);
    } finally {
      setLoading(false);
    }
  };
  
  const actionTemplate = (rowData) => (
    <div className="action-buttons">
      <div
        className={`toggle-switch ${rowData.isActive ? "active" : "inactive"}`}
        onClick={() => updateStatus(rowData.id, !rowData.isActive)}
      >
        <div
          className={`toggle-knob ${rowData.isActive ? "active" : "inactive"}`}
        />
      </div>
      <Button className="custom-btn-edit" onClick={() => editAction(rowData)}>
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
    </div>
  );

  const exportCSV = () => {
    const exportData = gateKeeperList.map((item) => ({
      Name: item.name,
      "Mobile Number": item.phoneNumber,
      CNIC: item.cnicNumber,
      Email: item.email,
      Address: item.address,
      // Status: item.isActive ? "Active" : "In-active",
      "Modified Date": formatTo12Hour(item.modifiedDate),
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    worksheet["!cols"] = [
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
    ];

    XLSX.utils.book_append_sheet(workbook, worksheet, "GateKeeper Data");

    XLSX.writeFile(workbook, "gateKeeper_data.xlsx");
  };

  useEffect(() => {
    GetGatesKeeper();
  }, [res]);

  const columnsData = [
    {
      header: "Name",
      field: "name",
      body: (rowData) => renderTextWithTooltip(rowData, "name", "bottom"),
    },
    {
      header: "Mobile Number",
      field: "phoneNumber",
    },
    {
      header: "CNIC",
      field: "cnicNumber",
    },
    {
      header: "Email",
      field: "email",
      body: (rowData) =>
        emailsRenderTextWithTooltip(rowData, "email", "bottom"),
    },
    {
      header: "Address",
      field: "address",
      body: (rowData) => renderTextWithTooltip(rowData, "address", "bottom"),
    },
    {
      header: "Date",
      field: "modifiedDate",
      body: (rowData) => formatDate(rowData.modifiedDate),
    },
    {
      header: "Status",
      body: (rowData) => (
        <Tag
          className={rowData.isActive ? "p-tag-success" : "p-tag-danger"}
          style={{ width: "100px", whiteSpace: "nowrap" }}
        >
          {rowData.isActive ? "Active" : "In-active"}
        </Tag>
      ),
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const rowExpansionTemplate = useCallback((data) => {
    return (
      <div className="p-3">
        <DataTable value={data.accessControls} responsiveLayout="scroll">
          <Column field="gate.number" header="Gate Number" />
          <Column field="gate.description" header="Gate Description" />
          {/* <Column
            field="gate.supportsOfflineMode"
            header="Gate Status"
            body={(rowData) =>
              rowData.gate.supportsOfflineMode ? (
                <Tag severity="warning" value="Offline" />
              ) : (
                <Tag severity="success" value="Online" />
              )
            }
          /> */}
          <Column
            field="modifiedDate"
            header="Date"
            body={(rowData) => formatDate(rowData.modifiedDate)}
          />
        </DataTable>
      </div>
    );
  }, []);

  return (
    <>
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={editable ? "Edit Gate Keeper" : "Add Gate Keeper"}
        className="dialog-size"
      >
        <AddEditGateKeeper
          dialog={dialog}
          editable={editable}
          rowData={rowselect}
          setDialog={setDialog}
          onHide={onHide}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive gap-3">
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          />

          <Button
            className="custom-btn"
            label="Add New"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setDialog(true);
            }}
          />
        </div>
      </div>
      <div className="card">
        {loading && <Loader />}
        <DataTable
          value={gateKeeperList}
          globalFilterFields={["name", "userName", "mobileNumber"]}
          scrollable
          // scrollHeight="450px"
          filters={filters}
          paginator
          rows={10}
          ref={dt}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "3em" }} />

          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
};

export default GateKeeper;
