import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import appUrl from "../../../../constants/appUrl";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../components/loader";
import apiClient from "../../../../services/axios_api";

const QrSetting = () => {
  const [qrSettingType, setQrSettingType] = useState("");
  const [areLimitsApplied, setAreLimitsApplied] = useState(null);

  const [timeBasedExpirationDate, setTimeBasedExpirationDate] = useState(null);
  const [scanBasedExpirationDate, setScanBasedExpirationDate] = useState(null);
  const [scanLimit, setScanLimit] = useState("");
  const [existingSetting, setExistingSetting] = useState(null);
  const apiUrl = `${appUrl.baseUrl}/api/QRCodeSettings`;
  const user = useSelector((state) => state.auth.user);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [timeError, setTimeError] = useState("");
  const [settingTypeError, setSettingTypeError] = useState("");
  const [modifiedDate, setModifiedDate] = useState(null);
  const [scanLimitError, setScanLimitError] = useState("");
  const [scanExpirationError, setScanExpirationError] = useState("");

  useEffect(() => {
    const fetchExistingSetting = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get(`${apiUrl}/all`);
        const setting = response.data?.data?.[0];
        // console.log(setting)

        if (setting) {
          setExistingSetting(setting);
          setAreLimitsApplied(setting.areLimitsApplied);
          setQrSettingType(setting.settingType.toLowerCase());
          setModifiedDate(new Date(setting.modifiedDate));

          if (
            setting.settingType.toLowerCase() === "time-based" &&
            setting.expiryDate
          ) {
            setTimeBasedExpirationDate(new Date(setting.expiryDate));
          } else if (
            setting.settingType.toLowerCase() === "scan-based" &&
            setting.expiryDate
          ) {
            setScanBasedExpirationDate(new Date(setting.expiryDate));
          }

          setScanLimit(setting.scanLimit || "");
        }
      } catch (error) {
        toast.error("Error fetching existing QR Setting.");
      } finally {
        setLoading(false);
      }
    };

    fetchExistingSetting();
  }, [token]);

  const handleTypeChange = (type) => {
    setQrSettingType(type);
    setTimeError("");
    setSettingTypeError("");
    setScanLimitError("");
    setScanExpirationError("");
  };

  const handleAreLimitsAppliedChange = (e) => {
    setAreLimitsApplied(e.checked);
    if (!e.checked) {
      setQrSettingType("");
      setTimeBasedExpirationDate(null);
      setScanBasedExpirationDate(null);
      setTimeError("");
      setSettingTypeError("");
      setScanLimitError("");
      setScanExpirationError("");
    }
  };

  const handleSubmit = async () => {
    let valid = true;

    if (areLimitsApplied && !qrSettingType) {
      setSettingTypeError("Please select one QR setting option.");
      valid = false;
    }

    if (qrSettingType === "time-based") {
      if (!timeBasedExpirationDate) {
        setTimeError(
          "Please select an expiration date for Time-Based setting."
        );
        valid = false;
      } else {
        setTimeError("");
      }
    }

    if (qrSettingType === "scan-based") {
      if (!scanBasedExpirationDate) {
        setScanExpirationError(
          "Please select an expiration date for Scan-Based setting."
        );
        valid = false;
      } else {
        setScanExpirationError("");
      }

      if (!scanLimit || scanLimit <= 0) {
        setScanLimitError("Please enter a scan limit.");
        valid = false;
      } else {
        setScanLimitError("");
      }
    }

    if (!valid) return;

    const payload = {
      areLimitsApplied,
      settingType: qrSettingType === "time-based" ? "Time-Based" : "Scan-Based",
      organizationId: existingSetting?.organizationId,
      scanLimit: qrSettingType === "scan-based" ? Number(scanLimit) : 0,
      expiryDate:
        qrSettingType === "time-based"
          ? moment(timeBasedExpirationDate).isValid()
            ? moment(timeBasedExpirationDate).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD") 
          : moment(scanBasedExpirationDate).isValid()
          ? moment(scanBasedExpirationDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"), 
      createdAt: existingSetting
        ? existingSetting.createdDate
        : new Date().toISOString(),
    };
    setLoading(true);

    try {
      if (existingSetting) {
        await apiClient.put(`${apiUrl}/update`, {
          ...payload,
          id: existingSetting.id,
        });
        toast.success("QR Setting updated successfully!");
      } else {
        await apiClient.post(`${apiUrl}/add`, payload);
        toast.success("QR Setting saved successfully!");
      }
    } catch (error) {
      toast.error("Error saving QR Setting.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="qr-setting-container">
        <div className="qr-setting-card">
          <div className="checkbox-group">
            <Checkbox
              inputId="areLimitsApplied"
              checked={areLimitsApplied}
              onChange={handleAreLimitsAppliedChange}
              style={{ position: "relative", top: "2px" }}
            />
            <label htmlFor="areLimitsApplied" style={{ fontWeight: "bold" }}>
              {" "}
              Set the Offline QR setting according to your preference{" "}
            </label>
          </div>

          {areLimitsApplied && (
            <>
              <label
                htmlFor="areLimitsApplied"
                className="my-2"
                style={{ fontWeight: "bold" }}
              >
                Choose one option to apply setting:
              </label>

              <div className="checkbox-group">
                <Checkbox
                  inputId="timeBased"
                  value="time-based"
                  onChange={(e) => handleTypeChange(e.value)}
                  checked={qrSettingType === "time-based"}
                  style={{ position: "relative", top: "2px" }}
                />
                <label htmlFor="timeBased">Time-Based</label>
              </div>

              {qrSettingType === "time-based" && (
                <div className="field md:col-6">
                  <label className="mb-2" style={{ fontWeight: "bold" }}>
                    Expiration Date<span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <Calendar
                    id="timeBasedExpirationDate"
                    value={timeBasedExpirationDate}
                    onChange={(e) => {
                      setTimeBasedExpirationDate(e.value);
                      if (e.value) {
                        setTimeError("");
                      }
                    }}
                    dateFormat="yy-mm-dd"
                    showIcon
                    minDate={new Date()}
                    placeholder="Select Date"
                  />
                  <br />
                  {timeError && (
                    <small className="error">{timeError}</small>
                  )}{" "}
                </div>
              )}

              <div className="checkbox-group">
                <Checkbox
                  inputId="scanBased"
                  value="scan-based"
                  onChange={(e) => handleTypeChange(e.value)}
                  checked={qrSettingType === "scan-based"}
                  style={{ position: "relative", top: "2px" }}
                />
                <label htmlFor="scanBased">Scan-Based</label>
              </div>

              {qrSettingType === "scan-based" && (
                <>
                  <div className="field md:col-6">
                    <label className="mb-2" style={{ fontWeight: "bold" }}>
                      Scan Limit<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <InputText
                      id="scanLimit"
                      value={scanLimit}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          value === "" ||
                          (value >= 1 && value <= 99) ||
                          value === "0"
                        ) {
                          setScanLimit(value);
                          setScanLimitError("");
                        }
                      }}
                      type="number"
                      min="0"
                      max="99"
                      maxLength="2"
                      placeholder="Enter number of scans"
                      className="input-number"
                      style={{ width: "225px" }}
                    />
                    <br />
                    {scanLimitError && (
                      <small className="error">{scanLimitError}</small>
                    )}
                  </div>

                  <div className="field md:col-6">
                    <label className="mb-2" style={{ fontWeight: "bold" }}>
                      Expiration Date<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <Calendar
                      id="scanBasedExpirationDate"
                      value={scanBasedExpirationDate}
                      onChange={(e) => {
                        setScanBasedExpirationDate(e.value);
                        if (e.value) {
                          setScanExpirationError("");
                        }
                      }}
                      dateFormat="yy-mm-dd"
                      showIcon
                      minDate={new Date()}
                      placeholder="Select Date"
                    />
                    <br />
                    {scanExpirationError && (
                      <small className="error">{scanExpirationError}</small>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {settingTypeError && (
            <small className="error">{settingTypeError}</small>
          )}
          <p
            className=""
            style={{
              color: "green",
              fontWeight: "600",
              fontSize: "12px",
              marginTop: "30px",
            }}
          >
            Note: If you select the checkbox, the same offline QR setting will
            be applied to your organization.
            <br /> Otherwise, if you uncheck it, the offline QR will remain open
            until the gate expires.
          </p>

          <div className="col-12 md:col-12">
            <div style={{ display: "grid", placeContent: "flex-end" }}>
              <Button
                loading={loading}
                className="custom-btn w100"
                label={"Save QR Setting"}
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QrSetting;
