import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddEditAdminControl from "../components/add_edit_adminControl";
import appUrl from "../../../../constants/appUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Loader from "../../../components/loader";
import editIcon from "../../../../../assets/icons/bx_edit.png";
import UpdateAdminStatus from "../components/edit_registration-requests_status";
import { Tag } from "primereact/tag";
import {
  formatDate,
  emailsRenderTextWithTooltip,
  renderTextWithTooltip,
  formatTo12Hour,
} from "../../../components/helper";
import * as XLSX from "xlsx";
import CustomImagePreview from "../../../components/custom_imagepreview";
import apiClient from "../../../../services/axios_api";

const AdminControl = () => {
  const [rowselect, setRowselect] = useState(null);
  const [dialog, setDialog] = useState();
  const [editDialog, setEditDialog] = useState();
  const [listData, setListData] = useState();
  const [res, setRes] = useState(false);
  const [editableTwo, setEditableTwo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const dt = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const GetAdminControl = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get("/AdminUser/all");
      setListData(response.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const onHide = () => {
    setDialog(false);
  };

  const onHideTwo = () => {
    setEditDialog(false);
  };

  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setDialog(true);
  };

 

  const updateStatus = async (id, isActive) => {
    setLoading(true);
  
    try {
      const payload = {
        id,
        isActive,
      };
      const response = await apiClient.put("/AdminUser/update/status", payload);
      if (response && response.data) {
        setRes(response.data);
        toast.success(`Organization Admin is now ${isActive ? "Active" : "In-active"}`);
      }
    } catch (error) {
      toast.error(`Failed to update status.`);
    } finally {
      setLoading(false);
    }
  };

  const actionTemplate = (rowData) => (
    <div className="action-buttons">
      <div
        className={`toggle-switch ${rowData.isActive ? "active" : "inactive"}`}
        onClick={() => updateStatus(rowData.id, !rowData.isActive)}
      >
        <div
          className={`toggle-knob ${rowData.isActive ? "active" : "inactive"}`}
        />
      </div>
      <Button
        className="custom-btn-edit"
        onClick={() => {
          editAction(rowData);
        }}
      >
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
    </div>
  );

  const exportCSV = () => {
    const exportData = listData.map((item) => ({
      Name: item.name,
      "Mobile Number": item.phoneNumber,
      "CNIC Number": item.cnicNumber,
      Email: item.email,
      Role: item.role,
      Address: item.address,
      "Modified Date": formatTo12Hour(item.modifiedDate),
      Status: item.isActive ? "Active" : "In-active",
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    worksheet["!cols"] = [
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
    ];

    XLSX.utils.book_append_sheet(workbook, worksheet, "Admin Data");

    XLSX.writeFile(workbook, "admin_data.xlsx");
  };

  useEffect(() => {
    GetAdminControl();
  }, [res]);

  const formattedStatus = (rowData) => {
    return (
      <div>
        <Tag
          className={rowData.isActive ? "p-tag-success" : "p-tag-danger"}
          style={{
            width: "100%",
            minWidth: "80px",
            maxWidth: "80px",
            whiteSpace: "nowrap",
          }}
        >
          {rowData.isActive ? "Active" : "In-active"}
        </Tag>
      </div>
    );
  };
  return (
    <>
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={editable ? "Edit Admin" : "Add Admin"}
        className="dialog-size"
      >
        <AddEditAdminControl
          dialog={dialog}
          editable={editable}
          rowData={rowselect}
          setDialog={setDialog}
          onHide={onHide}
          setRes={setRes}
        />
      </Dialog>

      <Dialog
        visible={editDialog}
        onHide={onHideTwo}
        header={"Update User Status"}
        className="dialog-size"
      >
        <UpdateAdminStatus
          dialog={editDialog}
          editable={editableTwo}
          rowData={rowselect}
          setDialog={setEditDialog}
          onHide={onHideTwo}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive gap-3">
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          />

          <Button
            className="custom-btn"
            label="Add New"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setDialog(true);
            }}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          value={listData}
          scrollable
          filters={filters}
          paginator
          rows={10}
          ref={dt}
          globalFilterFields={["number"]}
          className="custom-data-table"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column
            field="accessControls[0].organization.name"
            header="Organization Name"
            body={(rowData) =>
              rowData.accessControls[0]?.organization?.name || "--"
            }
          />
          <Column
            field="name"
            header="Name"
            body={(rowData) => renderTextWithTooltip(rowData, "name", "bottom")}
          />
          <Column field="phoneNumber" header="Mobile Number" />
          <Column
            field="cnicNumber"
            header="CNIC"
            body={(rowData) => rowData.cnicNumber || "--"}
          />
          <Column
            field="email"
            header="Email"
            body={(rowData) =>
              emailsRenderTextWithTooltip(rowData, "email", "bottom")
            }
          />
          <Column field="role" header="Role" />
          <Column
            field="address"
            header="Address"
            body={(rowData) =>
              renderTextWithTooltip(rowData, "address", "bottom")
            }
          />
          <Column
            field="modifiedDate"
            header="Date"
            body={(rowData) => formatDate(rowData.modifiedDate)}
          />
          <Column
            header="Image"
            body={(rowData) => (
              <CustomImagePreview
                src={`${appUrl.baseUrl}${rowData.profileImageUrl}`}
                alt=""
              />
            )}
          />
          <Column field="Status" header="Stauts" body={formattedStatus} />
          <Column field="" header="Action" body={actionTemplate} />
        </DataTable>
      </div>
    </>
  );
};

export default AdminControl;
