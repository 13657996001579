import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { formatTo12Hour } from "../../../components/helper";
import apiClient from "../../../../services/axios_api";

const LoginForm = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [otpDialogVisible, setOtpDialogVisible] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [receivedOtp, setReceivedOtp] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [otpExpiry, setOtpExpiry] = useState("");
  const [otpExpired, setOtpExpired] = useState(false);
  const [remainingTime, setRemainingTime] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      password: "",
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .required("Mobile Number is required")
        .matches(
          /^03\d{9}$/,
          "Mobile Number must start with 03 and be 11 digits long"
        )
        .length(11, "Mobile Number must be 11 digits long"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          mobileNumber: values.mobileNumber,
          secret: values.password,
        };

        const response = await apiClient.post("/Account/login", payload);
        const responseData = response.data;
        // console.log(responseData);
        if (responseData.responseCode === 200) {
          setMobileNumber(values.mobileNumber);
          const receivedOtp = responseData.data.otp.toString();
          // setOtp(receivedOtp.split("").map((digit) => digit));
          setReceivedOtp(receivedOtp);

          const expiryTime = responseData.data.otpExpiry;
          setOtpExpiry(expiryTime);
          setOtpDialogVisible(true);
          setOtpExpired(false);
        } else {
          const errorMessage =
            responseData.responseDescription || "Failed to perform operation";
          toast.error(errorMessage);
        }
      } catch (error) {
        console.error("Error occurred:", error);
        const errorMessage =
          error.response?.data?.responseDescription ||
          "Failed to perform operation";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!otpExpiry) return;

    const calculateRemainingTime = () => {
      const currentTime = new Date().getTime();
      const expiryTime = new Date(otpExpiry).getTime();

      const timeLeft = expiryTime - currentTime;
      if (timeLeft <= 0) {
        setOtpExpired(true);
        setRemainingTime("0:00");
      } else {
        const secondsLeft = Math.floor((timeLeft % (1000 * 60)) / 1000);
        const minutesLeft = Math.floor((timeLeft / (1000 * 60)) % 60);
        setRemainingTime(
          `${minutesLeft}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`
        );
      }
    };

    calculateRemainingTime();
    const intervalId = setInterval(calculateRemainingTime, 1000);

    return () => clearInterval(intervalId);
  }, [otpExpiry]);

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < 3) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleOtpSubmit = async () => {
    setOtpLoading(true);
    try {
      const otpPayload = {
        userName: mobileNumber,
        otp: Number(otp.join("")),
      };

      const otpResponse = await apiClient.post(
        "/Account/verify-otp",
        otpPayload
      );

      const otpResponseData = otpResponse.data;
      // console.log(otpResponse.data, "otpDtaa");

      if (otpResponseData.responseCode === 200 && otpResponseData.data?.user) {
        const userRole = otpResponseData.data.user.role;
        const user = otpResponseData.data.user;

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", otpResponseData.data.accessToken);

        if (user.mustChangePassword) {
          setShowPasswordDialog(true);
          return;
        }

        if (userRole === "SuperAdministrator") {
          history.push("/organizations");
        } else if (userRole === "Administrator") {
          history.push("/");
        }

        setTimeout(() => {
          toast.success(
            `${
              otpResponseData.responseDescription ||
              "OTP Verified successfully!"
            } OTP Verified! Logged in successfully!`
          );
        }, 100);
      } else if (otpResponseData.responseCode === 400) {
        toast.error(otpResponseData.responseDescription || "Invalid OTP!");
      } else {
        console.error(
          "OTP Verification Error:",
          otpResponseData.responseDescription
        );
        toast.error("Failed to verify OTP.");
      }
    } catch (error) {
      console.error("OTP Verification error:", error);
      toast.error("Internal server error");
    } finally {
      setOtpLoading(false);
    }
  };

  const changePasswordValidationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}/,
        "Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol."
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formikPasswordChange = useFormik({
    validationSchema: changePasswordValidationSchema,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      setPasswordLoading(true);
      try {
        const userId = JSON.parse(localStorage.getItem("user"));

        const payload = {
          id: userId?.id,
          password: values.password,
        };

        const response = await apiClient.put(`/Admin/update/password`, payload);

        // console.log(response.data, "data");

        if (response.data.responseCode === 200) {
          toast.success("Password updated successfully");

          const user = JSON.parse(localStorage.getItem("user"));
          if (user.role === "SuperAdministrator") {
            history.push("/organizations");
          } else if (user.role === "Administrator") {
            history.push("/");
          }

          setShowPasswordDialog(false);
        } else {
          toast.error(
            response.data.responseDescription ||
              "Unexpected error. Please try again."
          );
        }
      } catch (error) {
        let errorMessage = "Password update failed. Please try again.";
        if (error.response) {
          try {
            errorMessage =
              error.response.data.responseDescription ||
              "An unknown error occurred.";
          } catch (decryptError) {
            console.error("Error decrypting error response:", decryptError);
          }
        }

        toast.error(errorMessage);
      } finally {
        setPasswordLoading(false);
      }
    },
  });

  const handleRedirect = () => {
    history.push("/register");
  };

  return (
    <>
      {showPasswordDialog && (
        <Dialog
          visible={showPasswordDialog}
          header="Change Your Password"
          onHide={() => setShowPasswordDialog(false)}
          style={{ width: "350px" }}
          footer={
            <Button
              loading={passwordLoading}
              type="submit"
              label="Change Password"
              onClick={formikPasswordChange.handleSubmit}
              className="custom-btn"
              style={{ width: "100%" }}
            />
          }
        >
          <form
            className="p-fluid formgrid grid auth-login"
            onSubmit={formikPasswordChange.handleSubmit}
          >
            <div className="field md:col-12 mb-0">
              <label>Password</label>
              <Password
                id="password"
                name="password"
                placeholder="Enter password"
                value={formikPasswordChange.values.password}
                onChange={formikPasswordChange.handleChange}
                toggleMask
                feedback={false}
              />
              {formikPasswordChange.touched.password &&
              formikPasswordChange.errors.password ? (
                <div className="error">
                  {formikPasswordChange.errors.password}
                </div>
              ) : null}
            </div>
            <div className="field md:col-12 mb-0">
              <label>Confirm Password</label>
              <Password
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm your password"
                value={formikPasswordChange.values.confirmPassword}
                onChange={formikPasswordChange.handleChange}
                toggleMask
                feedback={false}
              />
              {formikPasswordChange.touched.confirmPassword &&
              formikPasswordChange.errors.confirmPassword ? (
                <div className="error">
                  {formikPasswordChange.errors.confirmPassword}
                </div>
              ) : null}

              <div
                style={{ fontSize: "10px", marginTop: "10px", color: "green" }}
              >
                Note: Password must b e at least 8 characters long and include 1
                uppercase letter, 1 lowercase letter, 1 number, and 1 symbol.
              </div>
            </div>
          </form>
        </Dialog>
      )}

      <Dialog
        header="Enter OTP"
        visible={otpDialogVisible}
        style={{ width: "350px" }}
        onHide={() => {
          setOtpDialogVisible(false);
          setOtp(new Array(4).fill(""));
          setOtpExpired(false);
          setOtpExpiry(null);
        }}
      >
        <div className="field">
          <label>
            An OTP has been sent to your email address. Please enter it here
          </label>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            {otp.map((digit, index) => (
              <InputText
                key={index}
                id={`otp-input-${index}`}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                maxLength={1}
                style={{ width: "50px", textAlign: "center" }}
                inputMode="numeric"
              />
            ))}
          </div>
        </div>
        <br />
        {otpExpired && (
          <p
            className="mx-auto text-center"
            style={{ color: "red", fontWeight: "bold" }}
          >
            OTP has expired. Please try again.
          </p>
        )}
        {otpExpiry && !otpExpired && (
          <p
            className="mx-auto text-center"
            style={{ color: "green", fontWeight: "bold" }}
          >
            OTP will expire at: {formatTo12Hour(otpExpiry)}
            <br /> (Remaining Time left: {remainingTime})
          </p>
        )}
        <div
          className="field"
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            loading={otpLoading}
            label="Verify OTP"
            className="custom-btn p-button"
            onClick={handleOtpSubmit}
            disabled={otp.some((digit) => digit === "")}
          />
        </div>
      </Dialog>

      <div className="p-fluid formgrid grid auth-login">
        <div className="field md:col-4"></div>
        <div
          className="field md:col-4"
          style={{
            background: "white",
            padding: "40px",
            borderRadius: "10px",
            backdropFilter: "blur(12px)",
          }}
        >
          <div className="text-center">
            <img
              src={"assets/layout/images/Zindigi.svg"}
              alt="logo"
              style={{ width: "150px" }}
            />
          </div>
          <br />
          <h4 className="auth-welcome mt-3 text-center">
            Log in to your account
          </h4>
          <p className="text-center">
            Welcome back! Please enter your details.
          </p>
          <form
            style={{ width: "100%" }}
            className="grid p-fluid justify-content-left align-items-left mt-5"
            onSubmit={formik.handleSubmit}
          >
            <div className="field md:col-12 mb-0">
              <label>
                Mobile number <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                maxLength={11}
                id="mobileNumber"
                name="mobileNumber"
                value={formik.values.mobileNumber}
                placeholder="Enter Mobile Number"
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("mobileNumber", sanitizedValue);
                }}
              />
              {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                <div className="error">{formik.errors.mobileNumber}</div>
              ) : null}
            </div>

            <div className="field md:col-12 mb-0">
              <label>
                Password <span className="asteric">&nbsp;*</span>
              </label>
              <Password
                className="auth-welcome"
                placeholder="Enter password"
                type="text"
                id="password"
                name="password"
                maxLength={30}
                value={formik.values.password}
                toggleMask
                onChange={formik.handleChange}
                feedback={false}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>

            <div className="field md:col-12 mb-0">
              <Button
                loading={loading}
                type="submit"
                className="custom-btn mt-3"
                label="Log In"
                disabled={loading}
              />
            </div>
            <p className="mx-auto" style={{ textAlign: "center" }}>
              If you don't have an account?{" "}
              <a onClick={handleRedirect}>Signup here</a>
            </p>
          </form>
        </div>
        <div className="field md:col-4"></div>
      </div>
    </>
  );
};

export default LoginForm;
