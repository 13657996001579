import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import { FileUpload } from "primereact/fileupload";
import apiClient from "../../../../services/axios_api";

const RegisterScreen = () => {
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [imageBase64, setImageBase64] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.files[0];
    setFileName(file.name);
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result.split(",")[1];
      setImageBase64(base64Image);
      formik.setFieldValue("logoImageBase64", base64Image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .required("Mobile Number is required")
      .matches(
        /^03\d{9}$/,
        "Mobile Number must start with 03 and be 11 digits long"
      )
      .length(11, "Mobile Number must be 11 digits long"),
    // address: Yup.string().required("Address is required"),
    organizationName: Yup.string().required("Organization Name is required"),
    // organizationAddress: Yup.string().required(
    //   "Organization Address is required"
    // ),
    // organizationEmail: Yup.string()
    //   .email("Invalid email format")
    //   .required("Organization Email is required"),
    organizationPhoneNumber: Yup.string()
      .matches(
        /^(03\d{9}|051\d{7})$/,
        "ORG Number must start with 03 and be 11 digits long, or start with 051 and be 10 digits long"
      )
      .notRequired(),
    logoImageBase64: Yup.string().required(
      "Organization Logo image is required"
    ),
  });

  // Formik hook
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      organizationName: "",
      organizationAddress: "",
      organizationEmail: "",
      organizationPhoneNumber: "",
      logoImageBase64: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        const formattedValues = {
          ...values,
          phoneNumber: values.phoneNumber,
          organizationPhoneNumber: values.organizationPhoneNumber,
        };
        const response = await apiClient.post(
          "/RegistrationRequests/add",
          formattedValues
        );
        setShowDialog(true);
      } catch (error) {
        const errorMessage =
          error.response?.data?.responseDescription ||
          "Operation failed, please try again";

        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleRedirect = () => {
    history.push("/");
  };

  const dialogFooter = (
    <div>
      <Button
        label="Okay"
        icon="pi pi-check footer-popup-dialog"
        onClick={handleRedirect}
        style={{
          background: "var(--primary-color)",
          border: "2px solid var(--primary-color)",
        }}
      />
    </div>
  );

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = "https://placehold.co/500x500?text=No+Image";
  };

  return (
    <div>
      <ConfirmDialog
        header="Success"
        visible={showDialog}
        footer={dialogFooter}
        message={
          <div style={{ textAlign: "center", lineHeight: "1.5" }}>
            <br />
            Registered Successfully!
            <br />
            It is now awaiting approval from the super admin.
            <br />
          </div>
        }
      />

      <div className="p-fluid formgrid grid auth-login">
        <div className="field md:col-4"></div>
        <div
          className="field md:col-4"
          style={{
            background: "white",
            padding: "40px",
            borderRadius: "10px",
            backdropFilter: "blur(12px)",
          }}
        >
          <div className="text-center">
            <img
              src={"assets/layout/images/Zindigi.svg"}
              alt="logo"
              style={{ width: "150px" }}
            />
          </div>
          <br />
          <h4 className="auth-welcome mt-3 text-center">
            Register Your Organization Here
          </h4>
          <p className="text-center">Welcome! Please enter your details.</p>

          <form
            onSubmit={formik.handleSubmit}
            className="grid p-fluid justify-content-left align-items-left mt-5"
          >
            <div className="field md:col-6 mb-0">
              <label>
                Name <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                keyfilter={/^[a-zA-Z\s]*$/}
                maxLength={30}
                name="name"
                placeholder="Enter Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={
                  formik.errors.name && formik.touched.name ? "p-invalid" : ""
                }
              />
              {formik.errors.name && formik.touched.name && (
                <small className="p-error">{formik.errors.name}</small>
              )}
            </div>

            <div className="field md:col-6 mb-0">
              <label>
                Email
                <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                name="email"
                placeholder="Enter Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={
                  formik.errors.email && formik.touched.email ? "p-invalid" : ""
                }
              />
              {formik.errors.email && formik.touched.email && (
                <small className="p-error">{formik.errors.email}</small>
              )}
            </div>

            <div className="field md:col-6 mb-0">
              <label>
                Mobile Number
                <span className="asteric">&nbsp;*</span>
              </label>

              <InputText
                maxLength={11}
                id="phoneNumber"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                placeholder="Enter Mobile Number"
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("phoneNumber", sanitizedValue);
                }}
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <small className="p-error">{formik.errors.phoneNumber}</small>
              )}
            </div>

            <div className="field md:col-6 mb-0">
              <label>
                Address
                {/* <span className="asteric">&nbsp;*</span> */}
              </label>
              <InputText
                maxLength={30}
                name="address"
                placeholder="Enter Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                className={
                  formik.errors.address && formik.touched.address
                    ? "p-invalid"
                    : ""
                }
              />
              {/* {formik.errors.address && formik.touched.address && (
                <small className="p-error">{formik.errors.address}</small>
              )} */}
            </div>

            <div className="field md:col-6 mb-0">
              <label>
                Organization Name <span className="asteric">&nbsp;*</span>
              </label>
              <InputText
                keyfilter={/^[a-zA-Z\s]*$/}
                maxLength={30}
                name="organizationName"
                placeholder="Enter Organization Name"
                value={formik.values.organizationName}
                onChange={formik.handleChange}
                className={
                  formik.errors.organizationName &&
                  formik.touched.organizationName
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.organizationName &&
                formik.touched.organizationName && (
                  <small className="p-error">
                    {formik.errors.organizationName}
                  </small>
                )}
            </div>

            <div className="field md:col-6 mb-0">
              <label>
                Organization Address
                {/* <span className="asteric">&nbsp;*</span> */}
              </label>
              <InputText
                maxLength={30}
                name="organizationAddress"
                placeholder="Enter Organization Address"
                value={formik.values.organizationAddress}
                onChange={formik.handleChange}
                className={
                  formik.errors.organizationAddress &&
                  formik.touched.organizationAddress
                    ? "p-invalid"
                    : ""
                }
              />
              {/* {formik.errors.organizationAddress &&
                formik.touched.organizationAddress && (
                  <small className="p-error">
                    {formik.errors.organizationAddress}
                  </small>
                )} */}
            </div>

            <div className="field md:col-6 mb-0">
              <label>
                Organization Email
                {/* <span className="asteric">&nbsp;*</span> */}
              </label>
              <InputText
                name="organizationEmail"
                placeholder="Enter Organization Email"
                value={formik.values.organizationEmail}
                onChange={formik.handleChange}
                className={
                  formik.errors.organizationEmail &&
                  formik.touched.organizationEmail
                    ? "p-invalid"
                    : ""
                }
              />
              {/* {formik.errors.organizationEmail &&
                formik.touched.organizationEmail && (
                  <small className="p-error">
                    {formik.errors.organizationEmail}
                  </small>
                )} */}
            </div>

            <div className="field md:col-6 mb-0">
              <label>
                Organization Number
                {/* <span className="asteric">&nbsp;*</span> */}
              </label>

              <InputText
                maxLength={11}
                id="organizationPhoneNumber"
                name="organizationPhoneNumber"
                value={formik.values.organizationPhoneNumber}
                placeholder="Enter Organization Number"
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue(
                    "organizationPhoneNumber",
                    sanitizedValue
                  );
                }}
              />
              {formik.errors.organizationPhoneNumber &&
                formik.touched.organizationPhoneNumber && (
                  <small className="p-error">
                    {formik.errors.organizationPhoneNumber}
                  </small>
                )}
            </div>

            <div className="field md:col-6 mb-0">
              <label htmlFor="logoImageBase64">
                Upload Organization Logo{" "}
                <span className="Staric-Custom"> *</span>
              </label>
              <FileUpload
                mode="basic"
                name="logoImageBase64"
                accept="image/*"
                maxFileSize={1000000}
                onSelect={handleImageUpload}
                auto
              />
              {fileName && (
                <p
                  style={{
                    marginBottom: "-8px",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  Selected file:
                </p>
              )}

              {formik.values.logoImageBase64 && (
                <div style={{ marginTop: "10px" }}>
                  <img
                    src={`data:image/jpeg;base64,${formik.values.logoImageBase64}`}
                    alt="Profile"
                    onError={handleImageError}
                    style={{
                      width: "100%",
                      height: "100px",
                      objectFit: "cover",
                      border: "1px solid #ced4da",
                      borderRadius: "12px",
                    }}
                  />
                </div>
              )}
              {formik.touched.logoImageBase64 &&
              formik.errors.logoImageBase64 ? (
                <div className="error">{formik.errors.logoImageBase64}</div>
              ) : null}
            </div>
            <div className="field md:col-12 mb-0">
              <Button
                loading={loading}
                type="submit"
                className="custom-btn mt-3"
                label="Register"
                disabled={formik.isSubmitting}
              />
            </div>
          </form>

          <p className="mx-auto" style={{ textAlign: "center" }}>
            Already have an account? <a onClick={handleRedirect}>Login here</a>
          </p>
        </div>
        <div className="field md:col-4"></div>
      </div>
    </div>
  );
};

export default RegisterScreen;
